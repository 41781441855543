import React from 'react';
import Info from './info';

const FormInput = ({
  id,
  name,
  label,
  type,
  className,
  placeholder,
  accept,
  value,
  search = false,
  inputRef,
  onChange,
  onKeyDown,
  readOnly,
  error,
  errorMessage,
  infos,
  CustomLabel,
  theme,
  ...rest
}) => {
  const checkBoxInputTypes = ['checkbox', 'radio'];
  const themes = ['primary', 'secondary'];
  const selectedTheme = themes.includes(theme) ? theme : 'primary';
  const selectedThemeClass = search
    ? 'bg-color-off-white rounded-md h-[35px] p-[15px] border-[0.5px] border-color-gray mt-[10px] text-[12px] text-color-black'
    : selectedTheme === 'primary' && readOnly
    ? 'bg-color-off-white border rounded-md h-[35px] p-[15px] mt-[10px] text-[12px] text-color-black'
    : 'bg-white border rounded-md h-[35px] p-[15px] mt-[10px] text-[12px] text-color-black';

  const errorClass = '!bg-[#FBEDEA] !border-error focus:!border-error';

  return (
    <div className='pb-[15px] flex flex-col'>
      {!checkBoxInputTypes.includes(type) ? (
        <div className='flex flex-col'>
          {label && (
            <label className='text-[12px] leading-[23px] font-normal text-color-gray -mb-2'>
              {label}
            </label>
          )}
          <input
            name={name}
            type={type}
            placeholder={placeholder}
            accept={accept}
            // defaultValue={value}
            value={value}
            className={` shadow-sm ${selectedThemeClass} ${className} ${
              error && errorClass
            }`}
            ref={inputRef}
            onChange={onChange}
            onKeyDown={onKeyDown}
            readOnly={readOnly ? true : false}
            {...rest}
          />
        </div>
      ) : (
        <div className='flex'>
          <input
            placeholder={placeholder}
            className={`shadow-sm ${className}`}
            name={name}
            type={type}
            defaultValue={value}
            ref={inputRef}
            id={id}
            readOnly={readOnly ? true : false}
            onChange={onChange}
            {...rest}
          />
          {CustomLabel}
          <label className='form-check-label ml-2' htmlFor={id}>
            {label}
          </label>
        </div>
      )}
      {error && (
        <span className='text-error text-[13px] italic'>* {errorMessage}</span>
      )}
      {infos && <Info infos={infos} />}
    </div>
  );
};

export default FormInput;
